import { Color } from '@mathison-inc/components'
import {
  Popper,
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@mui/material'
import { BiasScannerContext } from 'context/BiasScannerContext'
import React, { useContext } from 'react'
import { getColorsFromCategory } from 'utils/highlightHelper'

const ReplacementPopper = () => {
  const {
    popupAnchorEl,
    explaination,
    replaceTerm,
    selectedInput,
    handleReplacement,
    isScanning,
    selectedCategory,
    adjustHighlight,
    removeCurrentHighlight,
    closePopup
  } = useContext(BiasScannerContext)
  const { backgroundColor, labelColor } = selectedCategory
    ? getColorsFromCategory(selectedCategory)
    : { backgroundColor: Color.green300, labelColor: Color.grey600 }

  return (
    <Popper
      open={Boolean(popupAnchorEl)}
      anchorEl={popupAnchorEl}
      container={document.getElementsByClassName('editorContainer')![0]}
      sx={{
        zIndex: 10000,
        borderRadius: '8px',
        p: 3,
        boxShadow:
          '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)',

        bgcolor: 'white',
        width: 444
      }}
    >
      <Grid container>
        <Box
          sx={{
            width: 4,
            height: 16,
            backgroundColor: selectedCategory ? labelColor : Color.grey600
          }}
        />
        <Typography
          variant='formLabel'
          color={Color.grey600}
          marginLeft={'8px'}
          sx={{ textTransform: 'uppercase', fontSize: '12px' }}
        >
          {selectedCategory}
        </Typography>
      </Grid>

      <Typography variant='h5' marginTop='18px'>
        {selectedInput}
      </Typography>
      <Typography variant='body2' marginTop='18px'>
        {explaination}
      </Typography>
      <Grid container marginTop='18px'>
        <Typography
          variant='formLabel'
          sx={{
            textTransform: 'uppercase',
            fontSize: '12px',
            lineHeight: '32px'
          }}
        >
          Suggestions:
        </Typography>
        <Button
          variant='contained'
          sx={{
            height: '32px',
            borderRadius: '24px',
            backgroundColor,
            color: 'black',
            '&:hover': {
              backgroundColor: backgroundColor,
              color: 'black'
            },
            marginLeft: '8px'
          }}
          onClick={() => {
            if (isScanning) return
            handleReplacement(replaceTerm)
            adjustHighlight()
          }}
        >
          {isScanning ? <CircularProgress size='20px' /> : replaceTerm}
        </Button>
      </Grid>
      <Grid container marginTop='24px'>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            if (isScanning) return
            removeCurrentHighlight()
            closePopup()
            adjustHighlight()
          }}
        >
          {isScanning ? <CircularProgress size='20px' /> : 'Ignore term'}
        </Button>
        {/* <Button
          variant='contained'
          color='secondary'
          sx={{ marginLeft: 'auto' }}
        >
          Revert to original
        </Button> */}
      </Grid>
    </Popper>
  )
}

export default ReplacementPopper
